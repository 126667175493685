/*
 * APPLICATION IMPORTS
 */

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js'; // Included with Angular CLI.

(window as any).global = window;
